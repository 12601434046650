import React from "react";

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Home from "./pages/confmulheres/home";
import StatusPage from "./pages/confmulheres/status";
//import SucessoPage from "./pages/confmulheres/sucesso/status";
import HomePresbiderp from "./pages/presbiderp/home";
import StatusPagePresbiderp from "./pages/presbiderp/status";
import RedirectPage from "./pages/redirect"; 

function App(){
  return (
    <Router>
      
        <Routes>
          {/* Redirecionamento padrao */}
          <Route path="/" element={<RedirectPage />}/>
          
          {/* Conf mulheres */}
          <Route path="confmulheres/inscricao" element={<Home/>}/>
          <Route path="confmulheres/inscricao/status" element={<StatusPage/>}/>
          {/* <Route path="confmulheres/inscricao/sucesso" element={<SucessoPage/>}/> */}
          {/* Presbiderp */}
          <Route path="presbiderp/inscricao" element={<HomePresbiderp/>}/>
          <Route path="presbiderp/inscricao/status" element={<StatusPagePresbiderp/>}/>
          {/* <Route path="presbiderp/inscricao/sucesso" element={<SucessoPage/>}/> */}
        </Routes>
        
      </Router>
    
  )
}

export default App;