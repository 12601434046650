import React from "react";

import Formulario from "./form";
import './Home.css'

function Home(){
  return (
    
    <div className="theme-presbiderp container">
        <div className="theme-presbiderp image-container">
            <img src={`${process.env.PUBLIC_URL}/presbiderp.jpg`} alt="Mulher Orando" className="praying-image" />
        </div>
        <h1>Convenção Geral PRESBIDERP 2024</h1>
        <header className="theme-presbiderp form-container">   
         <Formulario/>
        </header>
    </div>    
    
  )
}

export default Home;