import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate} from "react-router-dom"
import Home from "../confmulheres/home";

 

function RedirectPage(){
    
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const confParam = searchParams.get('conf')
    const [queryParam, setQueryParam] = useState('')
    
    const validConfs = {
        "presbiderp": "presbiderp",
        "confmulheres": "confmulheres"
    }

    useEffect(() => {
        if (validConfs[confParam]) {
            navigate(`/${validConfs[confParam]}/inscricao`)
        }
    }, [queryParam])

    const MyRedirect = () => {
        if (confParam) {
            setQueryParam(confParam)
        }

        return (<Home />)
    }

    return (
        <MyRedirect />
    )
}

export default RedirectPage;