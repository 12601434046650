import React from "react";

import Formulario from "./form";
import './Home.css'


 

function Home(){
  return (
    
    <div className="theme-confmulheres container">
        <div className="theme-confmulheres image-container">
            <img src={`${process.env.PUBLIC_URL}/config.jpg`} alt="Mulher Orando" className="praying-image" />
        </div>
        <h1>Conferência de Mulheres 2024</h1>
        <header className="theme-confmulheres form-container">   
         <Formulario/>
        </header>
    </div>    
    
  )
}

export default Home;